/* @flow */

import * as Sentry from '@sentry/gatsby'
import { css } from 'glamor'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { saveUtmsInCookies } from '../../utils/utm'
import {
  Button,
  Dropdown,
  DropdownItem,
  Footer,
  FooterCopyright,
  FooterGroup,
  FooterLink,
  FooterList,
  Header,
  Icon,
  Inbound,
  Logo,
  NavBar,
  NavBarItem,
  NavBarLabel,
  Separator,
  Span,
  Title,
  Wrapper,
} from './../../components'
import { colors } from './../../theme'
import styles from './styles'

const { NEUTRAL_COLOR_00, SECONDARY_COLOR_60 } = colors

type Props = {
  children: React$Node,
  disableInbound?: boolean,
  url: string,
  name: string,
  title?: string,
  description?: string,
  contentType?: string,
  contentImage?: string,
  enableMinimalistNav?: boolean,
  enableMinimalistFooter?: boolean,
  brandText?: string,
  brandInformations?: string,
}

export default class DefaultLayout extends React.Component<Props> {
  static defaultProps = {
    disableInbound: false,
    contentType: 'website',
    contentImage: 'https://wino.fr/images/image_share.png',
    enableMinimalistNav: false,
    enableMinimalistFooter: false,
  }

  handleShowIntercom = (event: SyntheticEvent<>) => {
    event.preventDefault()
    if (typeof window !== 'undefined' && 'Intercom' in window) {
      window.Intercom('show')
    }
  }

  componentDidMount() {
    saveUtmsInCookies(location.href)
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)
  }

  render() {
    const {
      children,
      disableInbound,
      url,
      name,
      title,
      description,
      contentType,
      contentImage,
      enableMinimalistNav,
      enableMinimalistFooter,
      brandText,
      brandInformations,
    } = this.props
    const headerTitle = title ? `Wino | ${title}` : 'Wino'
    return (
      <div className={css(styles.container)}>
        <Helmet>
          <title>{headerTitle}</title>
          <meta itemprop="name" content={name} />
          <link rel="canonical" href={url} />
          <meta itemprop="url" content={url} />
          {description && <meta name="description" content={description} />}
          <link rel="canonical" href={url} />
          <meta property="og:locale" content="fr_FR" />
          <meta property="og:type" content={contentType} />
          <meta property="og:image" content={contentImage} />
          <meta property="og:title" content={headerTitle} />
          {description && <meta property="og:description" content={description} />}
          <meta property="og:url" content={url} />
          <meta property="og:site_name" content="Wino" />
          <meta property="article:publisher" content="https://www.facebook.com/winofrance/" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={headerTitle} />
          <meta name="twitter:site" content="@winofrance" />
          <meta name="twitter:creator" content="@winofrance" />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={contentImage} />
          <meta name="facebook-domain-verification" content="ja716t1a3lngobctoktr79ib5woro2" />
          <script type="text/javascript">
            {`_linkedin_partner_id = "88699";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
          </script>
          <script type="text/javascript">
            {`(function(){var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})();`}
          </script>
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-eu1.hs-scripts.com/26472724.js"
          />
          <script src="https://js.stripe.com/v3/" />
          <script
            src="https://code.jquery.com/jquery-3.3.1.min.js"
            integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8="
            crossOrigin="anonymous"
          />
        </Helmet>
        <div className={css(styles.wrapper)}>
          <Header enableMinimalistNav={enableMinimalistNav} brandText={brandText}>
            <Logo />
            {!enableMinimalistNav && (
              <NavBar>
                <NavBarItem>
                  <NavBarLabel>Solutions</NavBarLabel>
                  <Dropdown>
                    <DropdownItem
                      to="/le-point-de-vente"
                      icon="point-of-sale"
                      title="Point de vente"
                      content="Encaissez vos clients"
                    />
                    <DropdownItem
                      to="/business"
                      icon="business"
                      title="Business"
                      content="Vendez aux professionnels"
                    />
                    <DropdownItem
                      to="/la-fidelite"
                      icon="fidelity"
                      title="Fidélité"
                      content="Fidélisez votre clientèle"
                    />
                    <DropdownItem
                      to="/tableau-de-bord"
                      icon="dashboard"
                      title="Tableau de bord"
                      content="Suivez vos activités"
                    />
                    <DropdownItem
                      to="/click-and-collect"
                      icon="click-and-collect"
                      title="Potico"
                      content="Créez votre boutique en ligne"
                    />
                    <DropdownItem
                      to="/multiboutique"
                      icon="multiboutique"
                      title="Multiboutique"
                      content="Gérez tous vos points de vente"
                    />
                    <DropdownItem
                      to="/e-commerce"
                      icon="ecommerce"
                      title="E-commerce"
                      content="Connectez Wino et votre site en ligne"
                    />
                  </Dropdown>
                </NavBarItem>
                <NavBarItem>
                  <NavBarLabel>Métiers</NavBarLabel>
                  <Dropdown>
                    <DropdownItem
                      to="/caviste"
                      icon="wineshop"
                      title="Caviste"
                      content="Le logiciel de gestion idéal pour les cavistes"
                    />
                    <DropdownItem
                      to="/epicerie"
                      icon="groceryshop"
                      title="Épicerie"
                      content="Gérez votre épicerie facilement"
                    />
                    <DropdownItem
                      to="/fromagerie"
                      icon="cheeseshop"
                      title="Fromagerie"
                      content="Tout pour gérer votre fromagerie"
                    />
                  </Dropdown>
                </NavBarItem>
                <NavBarItem>
                  <NavBarLabel to="/blog">Blog</NavBarLabel>
                </NavBarItem>
                <NavBarItem>
                  <NavBarLabel to="https://app.wino.fr/auth/login/from-website">
                    Se connecter
                  </NavBarLabel>
                </NavBarItem>
                <NavBarItem>
                  <Button size="small" to="/demander-une-demo">
                    Demander une démo
                  </Button>
                </NavBarItem>
              </NavBar>
            )}
          </Header>
          <div className={css(styles.content)}>
            {children}
            {!!brandInformations && (
              <Wrapper>
                <div className={css(styles.brandInformations)}>
                  <Span size="small">{brandInformations}</Span>
                </div>
              </Wrapper>
            )}
          </div>
          {disableInbound === false && (
            <Wrapper>
              <Inbound
                className={css(styles.inbound)}
                title={
                  <span>
                    Améliorez votre quotidien
                    <br />
                    de caviste et épicier
                    <br />
                    dès aujourd'hui !
                  </span>
                }
                inputPlaceholderText="Adresse mail"
                buttonText="Demander une démo"
                buttonLink="/demander-une-demo"
              />
            </Wrapper>
          )}
        </div>
        <Footer className={css(styles.footer)}>
          {!enableMinimalistFooter && (
            <FooterGroup>
              <Title level={4}>Nos produits</Title>
              <FooterList>
                <FooterLink to="/le-point-de-vente">Point de vente</FooterLink>
                <FooterLink to="/business">Business</FooterLink>
                <FooterLink to="/la-fidelite">Fidélité</FooterLink>
                <FooterLink to="/tableau-de-bord">Tableau de bord</FooterLink>
                <FooterLink to="/click-and-collect">Potico</FooterLink>
                <FooterLink to="/multiboutique">Multiboutique</FooterLink>
                <Separator tint={SECONDARY_COLOR_60} />
                <FooterLink to="/">Accueil</FooterLink>
                <FooterLink to="https://app.wino.fr/auth/login/from-website">
                  Se connecter
                </FooterLink>
                <FooterLink to="/demander-une-demo">Demander une démo</FooterLink>
                <FooterLink to="/contact">Échanger avec un expert</FooterLink>
                <FooterLink to="/devis">Obtenir un devis</FooterLink>
              </FooterList>
            </FooterGroup>
          )}
          {!enableMinimalistFooter && (
            <FooterGroup>
              <Title level={4}>Ressources</Title>
              <FooterList>
                <FooterLink to="/blog">Le blog</FooterLink>
                <FooterLink to="https://help.wino.fr/">Guide d'utilisation</FooterLink>
                <FooterLink to="https://www.notion.so/Travailler-chez-Wino-d9c92335e0f048b2919a63e8c11d1e21">
                  Travailler chez Wino
                </FooterLink>
                <FooterLink to="https://content.wino.fr/parrainage">
                  Demande de parrainage
                </FooterLink>
                <FooterLink to="https://developers.wino.fr/docs/introduction/start-here">
                  Pour les développeurs
                </FooterLink>
              </FooterList>
            </FooterGroup>
          )}
          <FooterGroup>
            <Title level={4}>Plus d'informations</Title>
            <FooterList>
              <FooterLink to="/conditions-generales-dutilisation">
                Conditions générales d’utilisation
              </FooterLink>
              <FooterLink to="/mentions-legales">Mentions légales</FooterLink>
            </FooterList>
          </FooterGroup>
          <FooterGroup>
            <Logo color={NEUTRAL_COLOR_00} className={css(styles.footerLogo)} />
            <FooterList>
              <FooterLink to="https://goo.gl/maps/1T3jB8pwMX8Ah8fC9">
                Wino Technologies
                <br />
                40, rue de Louvre
                <br />
                75001 Paris
              </FooterLink>
              <FooterLink to="tel:0183641233">01 83 64 12 33</FooterLink>
              <FooterLink to="mailto:hello@wino.fr">hello@wino.fr</FooterLink>
              <div className={css(styles.social)}>
                <a
                  className={css(styles.link)}
                  href="https://www.facebook.com/winofrance/"
                  target="__blank"
                >
                  <Icon color={NEUTRAL_COLOR_00} name="facebook" className={css(styles.facebook)} />
                </a>
                <a
                  className={css(styles.link)}
                  href="https://twitter.com/winofrance"
                  target="__blank"
                >
                  <Icon color={NEUTRAL_COLOR_00} name="twitter" className={css(styles.twitter)} />
                </a>
                <a
                  className={css(styles.link)}
                  href="https://www.instagram.com/wino_france/"
                  target="__blank"
                >
                  <Icon
                    color={NEUTRAL_COLOR_00}
                    name="instagram"
                    className={css(styles.instagram)}
                  />
                </a>
                <a
                  className={css(styles.link)}
                  href="https://www.linkedin.com/company/wino-technologies-s-a-s/"
                  target="__blank"
                >
                  <Icon color={NEUTRAL_COLOR_00} name="linkedin" className={css(styles.linkedin)} />
                </a>
              </div>
            </FooterList>
          </FooterGroup>
          <FooterCopyright />
        </Footer>
      </div>
    )
  }
}
